@import "./assets/sass/_variables";
@import "./assets/sass/_fonts";

@import "~bootstrap/scss/functions";

// Sass variable overrides
$enable-negative-margins: true;
$font-family-base: "Roboto", sans-serif;
$font-family-italianno: "Italianno", sans-serif;

$modal-content-border-radius: 0.75rem;
$modal-content-border-width: 0;
$modal-content-bg: #f3f3f3;

$badge-border-radius: 0.25rem;
$badge-font-size: "calc(var(--bs-body-font-size) * 0.938)";

$gray-100: rgba(249, 249, 247);
$gray-200: rgba(232, 232, 240);
$gray-300: rgba(222, 222, 230);
$gray-400: rgba(206, 206, 218);
$gray-500: rgba(167, 167, 190);
$gray-600: rgba(112, 107, 133);
$gray-700: rgba(70, 69, 89);
$gray-800: rgba(36, 34, 55);
$gray-900: rgba(25, 23, 43);

$blue-100: rgba(237, 241, 255);
$blue-200: rgba(179, 197, 255);
$blue-300: rgba(140, 165, 255);
$blue-400: rgba(105, 132, 255);
$blue-500: rgba(69, 84, 255);
$blue-600: rgba(49, 14, 236);
$blue-700: rgba(30, 19, 166);
$blue-800: rgba(19, 0, 125);
$blue-900: rgba(6, 0, 65);

$green-500: rgba(25, 135, 84, 1);
$purple-500: rgba(111, 66, 193, 1);

$offcanvas-border-width: 1px;

$card-bg: #fff;

// Override input element font size to respect the typography settings
$input-font-size: "var(--bs-body-font-size)";
$input-font-size-sm: "calc(var(--bs-body-font-size) * 0.875)";
$input-font-size-lg: "calc(var(--bs-body-font-size) * 1.25)";

$table-color: var(--bs-body-color);

@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/variables-dark";
@import "~bootstrap/scss/maps";
@import "~bootstrap/scss/utilities";

$utilities: map-merge(
    $utilities,
    (
        "border":
            map-merge(
                map-get($utilities, "border"),
                (
                    responsive: true,
                )
            ),
        "border-top":
            map-merge(
                map-get($utilities, "border-top"),
                (
                    responsive: true,
                )
            ),
        "border-bottom":
            map-merge(
                map-get($utilities, "border-bottom"),
                (
                    responsive: true,
                )
            ),
        "rounded":
            map-merge(
                map-get($utilities, "rounded"),
                (
                    responsive: true,
                    values:
                        map-merge(
                            map-get(map-get($utilities, "rounded"), "values"),
                            (
                                4: 0.375rem,
                            )
                        ),
                )
            ),
        "gap":
            map-merge(
                map-get($utilities, "gap"),
                (
                    responsive: true,
                    values:
                        map-merge(
                            map-get(map-get($utilities, "gap"), "values"),
                            (
                                4p5: $spacer * 2,
                                4p75: $spacer * 2.5,
                            )
                        ),
                )
            ),
        "padding":
            map-merge(
                map-get($utilities, "padding"),
                (
                    responsive: true,
                    values:
                        map-merge(
                            map-get(map-get($utilities, "padding"), "values"),
                            (
                                2p5: $spacer * 0.75,
                                4p5: $spacer * 2.5,
                            )
                        ),
                )
            ),
        "padding-y":
            map-merge(
                map-get($utilities, "padding-y"),
                (
                    responsive: true,
                    values:
                        map-merge(
                            map-get(map-get($utilities, "padding-y"), "values"),
                            (
                                0p5: $spacer * 0.125,
                                1p5: $spacer * 0.375,
                                2p5: $spacer * 0.75,
                                4p5: $spacer * 2.5,
                            )
                        ),
                )
            ),
        "padding-x":
            map-merge(
                map-get($utilities, "padding-x"),
                (
                    responsive: true,
                    values:
                        map-merge(
                            map-get(map-get($utilities, "padding-x"), "values"),
                            (
                                3p5: $spacer * 1.25,
                            )
                        ),
                )
            ),
        "margin-top":
            map-merge(
                map-get($utilities, "margin-top"),
                (
                    responsive: true,
                    values:
                        map-merge(
                            map-get(map-get($utilities, "margin-top"), "values"),
                            (
                                6: $spacer * 5,
                            )
                        ),
                )
            ),
        "margin-bottom":
            map-merge(
                map-get($utilities, "margin-bottom"),
                (
                    responsive: true,
                    values:
                        map-merge(
                            map-get(map-get($utilities, "margin-bottom"), "values"),
                            (
                                6: $spacer * 5,
                            )
                        ),
                )
            ),
        "font-weight":
            map-merge(
                map-get($utilities, "font-weight"),
                (
                    responsive: true,
                    values:
                        map-merge(
                            map-get(map-get($utilities, "font-weight"), "values"),
                            (
                                semi-bold: 500,
                            )
                        ),
                )
            ),
        "line-height":
            map-merge(
                map-get($utilities, "line-height"),
                (
                    responsive: true,
                    values:
                        map-merge(
                            map-get(map-get($utilities, "line-height"), "values"),
                            (
                                0p5: 0.5,
                            )
                        ),
                )
            ),
        "font-family":
            map-merge(
                map-get($utilities, "font-family"),
                (
                    values:
                        map-merge(
                            map-get(map-get($utilities, "font-family"), "values"),
                            (
                                signature: $font-family-italianno,
                            )
                        ),
                )
            ),
        "font-size":
            map-merge(
                map-get($utilities, "font-size"),
                (
                    values:
                        map-merge(
                            map-get(map-get($utilities, "font-size"), "values"),
                            (
                                1: calc(var(--bs-body-font-size) * 2.5),
                                2: calc(var(--bs-body-font-size) * 2.25),
                                4: calc(var(--bs-body-font-size) * 1.5),
                                5: calc(var(--bs-body-font-size) * 1.25),
                                6: calc(var(--bs-body-font-size) * 1),
                                7: calc(var(--bs-body-font-size) * 0.875),
                                8: calc(var(--bs-body-font-size) * 0.75),
                            )
                        ),
                )
            ),
        "width": (
            property: width,
            class: w,
            responsive: true,
            values: (
                15: 15%,
                25: 25%,
                35: 35%,
                50: 50%,
                65: 65%,
                75: 75%,
                100: 100%,
                auto: auto,
                fit-content: fit-content,
            ),
        ),
        "max-width": (
            property: max-width,
            class: mw,
            values: (
                100: 100%,
                75: 75%,
                70: 70%,
                50: 50%,
                25: 25%,
            ),
        ),
        "min-height": (
            property: min-height,
            class: min-h,
            values: (
                100: 100%,
                auto: auto,
            ),
        ),
        "box-shadow": (
            property: box-shadow,
            class: box-shadow,
            responsive: true,
            values: (
                0: none,
                1: $box-shadow-sm,
                2: $box-shadow,
                3: $box-shadow-lg,
            ),
        ),
        "position":
            map-merge(
                map-get($utilities, "position"),
                (
                    responsive: true,
                )
            ),
    )
);

@import "~bootstrap/scss/bootstrap";

:root {
    --bs-blue-200: #{$blue-200};
    --bs-blue-600: #{$blue-600};
    --bs-blue-700: #{$blue-700};

    --bs-purple-200: #{$purple-200};
    --bs-purple-600: #{$purple-600};
    --bs-purple-700: #{$purple-700};

    --bs-orange-200: #{$orange-200};
    --bs-orange-600: #{$orange-600};
    --bs-orange-700: #{$orange-700};
    --bs-orange-800: #{$orange-800};

    --bs-pink-200: #{$pink-200};
    --bs-pink-600: #{$pink-600};
    --bs-pink-700: #{$pink-700};

    --bs-teal-200: #{$teal-200};
    --bs-teal-600: #{$teal-600};
    --bs-teal-700: #{$teal-700};
    --bs-teal-800: #{$teal-800};

    --bs-cyan-200: #{$cyan-200};
    --bs-cyan-600: #{$cyan-600};
    --bs-cyan-700: #{$cyan-700};
    --bs-cyan-800: #{$cyan-800};

    --bs-danger-rgb: 220, 33, 49;

    --link-default-color: #0d6bf4;
    --link-mouseover-color: #0a58ca;
    --link-focus-color: #0a58ca;

    --btn-border-radius: 0rem;
    --bs-btn-padding-y: 0.375rem;
    --bs-btn-padding-x: 0.75rem;
    --btn-font-size: 1rem;
    --bs-btn-line-height: 1.5rem;

    --btn-primary-color-default: #007bff;
    --btn-primary-color-mouseOver: #0a58ca;
    --btn-primary-color-focusActive: #0a58ca;
    --btn-primary-color-disabled: #0a58ca50;

    --btn-filled-color-default: #007bff;
    --btn-filled-color-mouseOver: #0a58ca;
    --btn-filled-color-focusActive: #0a58ca;
    --btn-filled-color-disabled: #0a58ca50;

    --btn-ghost-color-default: #007bff;
    --btn-ghost-color-mouseOver: #0a58ca;
    --btn-ghost-color-focusActive: #0a58ca;
    --btn-ghost-color-disabled: #0a58ca50;

    --btn-primary-border-width: 0px;
    --btn-primary-border-color: #0a58ca;

    --btn-filled-border-width: 0px;
    --btn-filled-border-color-default: #007bff;
    --btn-filled-border-color-mouseOver: #0a58ca;
    --btn-filled-border-color-focusActive: #0a58ca;
    --btn-filled-border-color-disabled: #0a58ca50;

    --btn-ghost-border-width: 0px;
    --btn-ghost-border-color-default: #007bff;
    --btn-ghost-border-color-mouseOver: #0a58ca;
    --btn-ghost-border-color-focusActive: #0a58ca;
    --btn-ghost-border-color-disabled: #0a58ca50;

    --btn-ghost-text-color-default: #000;
    --btn-ghost-text-color-mouseOver: #ffffff;
    --btn-ghost-text-color-focusActive: #ffffff;
    --btn-ghost-text-color-disabled: #000;

    --btn-filled-text-color-default: #000;
    --btn-filled-text-color-mouseOver: #000;
    --btn-filled-text-color-focusActive: #000;
    --btn-filled-text-color-disabled: #000;

    --btn-primary-text-color-default: #000;
    --btn-primary-text-color-mouseOver: #000;
    --btn-primary-text-color-focusActive: #000;
    --btn-primary-text-color-disabled: #000;

    --btn-primary-focus-shadow-color: var(--default-shadow-color);
    --btn-filled-focus-shadow-color: var(--default-shadow-color);
    --btn-ghost-focus-shadow-color: var(--default-shadow-color);

    --btn-shadow: none;

    --heading-h1-color: #333333;
    --heading-h2-color: #333333;
    --heading-h3-color: #333333;
    --heading-h4-color: #333333;
    --heading-h5-color: #333333;
    --heading-h6-color: #333333;

    --btn-group-background-color: #efeff1;

    --table-background-color: #fff;

    --list-margin-bottom: none;

    --standard-form-max-width: 400px;

    --default-shadow-color: rgba(85, 85, 85, 0.25);

    --grid-shadow-color: #ebf0f5;

    --label-color: rgb(83, 83, 83);
    --separator-color: #dddddd;

    --radio-background-color-checked: var(--bs-body-color);
    --radio-border-color-checked: var(--bs-body-color);

    --check-control-margin-top: 0.25em;

    .offcanvas {
        --bs-offcanvas-bg: #fff;
    }
}

.text-gray-600 {
    color: var(--bs-gray-600);
}

.bg-gray-100 {
    background-color: var(--bs-gray-100);
}

.bg-gray-200 {
    background-color: var(--bs-gray-200) !important;
}

#root {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    background: var(--bs-body-bg);
}

.modal {
    .form-control {
        background-color: var(--bs-white);
    }

    .list-group-item {
        background-color: var(--bs-modal-bg);
    }
}

.offcanvas {
    @media screen and (max-width: 450px) {
        width: 100% !important;
    }
}

// Helper classes
.offcanvas-75 {
    width: 75% !important;

    @include media-breakpoint-down(md) {
        width: 100%;
    }
}

.offcanvas.offcanvas-end.offcanvas-90 {
    width: 90vw;

    @include media-breakpoint-down(md) {
        width: 100vw;
    }
}

.outline-0 {
    outline: 0;
}

.text-label {
    color: var(--label-color) !important;
}

.text-link {
    color: var(--link-default-color) !important;
}

.standard-form {
    max-width: var(--standard-form-max-width);
}

.form-control,
.form-control:focus,
.form-select,
.form-check-input {
    background-color: var(--bs-white);
}

.form-control,
.form-control:focus {
    &:read-only,
    &:disabled {
        background-color: var(--bs-gray-200);
    }
}

.form-check-input {
    &:checked {
        &:disabled {
            opacity: 1;
            background-color: var(--bs-gray-200);
            border-color: var(--bs-gray-200);
        }
    }
}

.form-select {
    &:disabled {
        background-color: var(--bs-gray-200);
    }
}

.form-group {
    input[type="date"],
    input[type="time"],
    input[type="datetime-local"] {
        width: 300px;
    }
}

.application-stepper {
    .MuiStep-root {
        min-width: 5rem;

        .MuiStepLabel-label,
        .MuiStepIcon-text {
            font-family: var(--bs-body-font-family);
        }

        .MuiStepLabel-iconContainer {
            .MuiSvgIcon-root {
                width: calc(var(--bs-body-font-size) * 2);
                height: calc(var(--bs-body-font-size) * 2);
            }
        }
    }

    .MuiStepIcon-root:not(.Mui-active) {
        .MuiStepIcon-text {
            fill: #000;
        }
    }
}

// Hide recaptcha badge
.grecaptcha-badge {
    visibility: hidden;
}

// Links
a,
.btn-link,
.btn-outline-link {
    color: var(--link-default-color);

    &:hover {
        color: var(--link-mouseover-color);
    }

    &:focus {
        color: var(--link-focus-color);
    }
}

.badge {
    --bs-badge-color: var(--badge-color-override);
}

.field-string {
    .text-muted {
        color: var(--bs-body-color) !important;
    }
}

// Buttons
.btn {
    font-size: var(--btn-font-size);
    border-radius: var(--btn-border-radius);
    --bs-btn-line-height: var(--bs-body-line-height);

    // Use font size relative to the screen size (5.5 * [screen width]/100) if it is too large
    @media screen and (max-width: 400px) {
        font-size: min(5.5vw, var(--btn-font-size));
    }

    &.btn-sm {
        font-size: calc(var(--btn-font-size) * 0.875);
    }

    &.btn-lg {
        font-size: calc(var(--btn-font-size) * 1.25);
    }

    &.btn-primary {
        background-color: var(--btn-primary-color-default);
        border: var(--btn-primary-border-width) solid var(--btn-primary-border-color);
        color: var(--btn-primary-text-color-default);
        box-shadow: var(--btn-shadow);

        &:focus {
            box-shadow: 0 0 0 0.25rem var(--btn-primary-focus-shadow-color);
        }

        &:hover {
            background-color: var(--btn-primary-color-mouseOver);
            color: var(--btn-primary-text-color-mouseOver);
        }

        &:focus,
        &:active,
        &.active {
            background-color: var(--btn-primary-color-focusActive);
            color: var(--btn-primary-text-color-focusActive);
        }

        &:disabled {
            background-color: var(--btn-primary-color-disabled);
            color: var(--btn-primary-text-color-disabled);
        }
    }

    &.btn-secondary:not(.ghost),
    &.filled {
        background-color: var(--btn-filled-color-default);
        border: var(--btn-filled-border-width) solid var(--btn-filled-border-color-default);
        box-shadow: var(--btn-shadow);
        color: var(--btn-filled-text-color-default);

        &:hover {
            background-color: var(--btn-filled-color-mouseOver);
            color: var(--btn-filled-text-color-mouseOver);
            border: var(--btn-filled-border-width) solid var(--btn-filled-border-color-mouseOver);
        }

        &:focus {
            background-color: var(--btn-filled-color-focusActive);
            color: var(--btn-filled-text-color-focusActive);
            border: var(--btn-filled-border-width) solid var(--btn-filled-border-color-focusActive);
            box-shadow: 0 0 0 0.25rem var(--btn-filled-focus-shadow-color);
        }

        &:active {
            background-color: var(--btn-filled-color-focusActive);
            color: var(--btn-filled-text-color-focusActive);
            border: var(--btn-filled-border-width) solid var(--btn-filled-border-color-focusActive);
        }

        &:disabled {
            background-color: var(--btn-filled-color-disabled);
            color: var(--btn-filled-text-color-disabled);
            border: var(--btn-filled-border-width) solid var(--btn-filled-border-color-disabled);
        }
    }

    &.ghost {
        background-color: transparent;
        border: var(--btn-ghost-border-width) solid var(--btn-ghost-border-color-default);
        box-shadow: var(--btn-shadow);
        color: var(--btn-ghost-text-color-default);

        &:hover {
            background-color: var(--btn-ghost-color-mouseOver);
            color: var(--btn-ghost-text-color-mouseOver);
            border-color: var(--btn-ghost-border-color-mouseOver);
        }

        &:focus {
            background-color: var(--btn-ghost-color-focusActive);
            color: var(--btn-ghost-text-color-focusActive);
            border-color: var(--btn-ghost-border-color-focusActive);
            box-shadow: 0 0 0 0.25rem var(--btn-ghost-focus-shadow-color);
        }

        &:active,
        &.active {
            background-color: var(--btn-ghost-color-focusActive);
            color: var(--btn-ghost-text-color-focusActive);
            border-color: var(--btn-ghost-border-color-focusActive);
        }

        &:disabled {
            background-color: var(--btn-ghost-color-disabled);
            color: var(--btn-ghost-text-color-disabled);
            border-color: var(--btn-ghost-border-color-disabled);
        }
    }

    &.btn-outline-secondary {
        --bs-btn-color: var(--btn-ghost-text-color-default);
        --bs-btn-border-color: var(--btn-ghost-text-color-default);

        --bs-btn-hover-color: var(--btn-ghost-text-color-mouseOver);
        --bs-btn-hover-bg: var(--btn-ghost-color-mouseOver);
        --bs-btn-hover-border-color: var(--btn-ghost-text-color-mouseOver);
        --bs-btn-focus-shadow-rgb: 108, 117, 125;

        --bs-btn-active-color: var(--btn-ghost-text-color-focusActive);
        --bs-btn-active-bg: var(--btn-ghost-color-focusActive);
        --bs-btn-active-border-color: var(--btn-ghost-color-focusActive);
        --bs-btn-active-shadow: var(--btn-shadow);

        --bs-btn-disabled-color: var(--btn-ghost-text-color-disabled);
        --bs-btn-disabled-bg: transparent;
        --bs-btn-disabled-border-color: var(--btn-ghost-text-color-disabled);
        --bs-gradient: none;

        &:focus {
            background-color: var(--btn-ghost-color-focusActive);
            color: var(--btn-ghost-text-color-focusActive);
            border-color: var(--btn-ghost-color-focusActive);
            box-shadow: 0 0 0 0.25rem var(--btn-ghost-focus-shadow-color);
        }
    }
}

// Toggle buttons
.btn-check:checked + .btn-primary,
.show > .btn-primary.dropdown-toggle {
    background-color: var(--btn-primary-color-default);
    color: var(--btn-primary-text-color-default);
}

.btn-check + .btn {
    &:hover {
        background-color: var(--btn-primary-color-mouseOver);
        color: var(--btn-primary-text-color-mouseOver);
    }
}

.btn-check:active + .btn-primary {
    background-color: var(--btn-primary-color-focusActive);
    color: var(--btn-primary-text-color-focusActive);
}

.btn-fixed-medium-size {
    --bs-btn-line-height: 1.5 !important;
    --bs-btn-padding-y: 0.375rem !important;
    --bs-btn-padding-x: 0.75rem !important;
    --btn-font-size: var(--bs-body-font-size) !important;
}

// Align check box and radio with the label. Otherwise, alignment breaks when changing line-height
.form-check {
    .form-check-input {
        margin-top: var(--check-control-margin-top);
    }
}

// Radio inputs
.form-check-input {
    &:checked {
        background-color: var(--radio-background-color-checked);
        border-color: var(--radio-border-color-checked);
    }
}

// List group
.list-group-item {
    color: var(--label-color);
    border-color: var(--separator-color);
    background-color: transparent;
}

// Dropdowns
.dropdown-menu {
    --bs-dropdown-font-size: var(--bs-body-font-size);
    --bs-dropdown-bg: var(--bs-white);
}

// Headers
h1 {
    color: var(--heading-h1-color);
}

h2 {
    color: var(--heading-h2-color);
}

h3 {
    color: var(--heading-h3-color);
}

h4 {
    color: var(--heading-h4-color);
}

h5 {
    color: var(--heading-h5-color);
    font-size: calc(var(--bs-body-font-size) * 1.25);
}

h6 {
    color: var(--heading-h6-color);
    font-size: calc(var(--bs-body-font-size) * 1);
}

// tables
.table:not(.data-grid):not(.table-v2) {
    --bs-table-bg: var(--table-background-color);
    --table-border-color: #dee2e6;
    line-height: initial;
    margin-bottom: 0;
    border-color: var(--table-border-color);

    > * {
        border-top: none;
    }

    thead {
        color: var(--label-color);
        border-color: inherit;
        background-color: var(--table-background-color);

        &.table-dark {
            color: #fff;
            border-color: #373b3e;
        }
    }

    tbody {
        border-top-color: var(--table-border-color) !important;
        > tr {
            background-color: var(--table-background-color);
            border-bottom: $border-width solid var(--table-border-color);
            box-shadow: none;

            > td {
                display: table-cell;
                vertical-align: middle;
            }
        }
    }

    tfoot {
        > tr {
            border-width: 0;
            border-color: var(--table-border-color);
            background-color: var(--table-background-color);
            padding: map-get($spacers, 2);
            border-top-width: 1px;
            display: table-row;

            > th {
                color: var(--label-color);
                border: none;
                background-color: var(--table-header-background-color);
                text-transform: uppercase;
            }
        }
    }
}
