.text-widget {
    &.text-opacity {
        opacity: 0.5;
    }

    .table-responsive {
        max-width: calc(100vw - 30px);
    }

    // Allow to resize images added to text widget.
    > img,
    :not(td) > img {
        max-width: 100%;
    }

    .table {
        display: flex;
        flex-direction: column;
        width: 100%;

        figcaption {
            order: 2;
        }

        .table-responsive {
            order: 3;
            &:has(> .caption-top) {
                order: 3;
            }
            &:has(> .caption-bottom) {
                order: 1;
            }

            table {
                width: 100%;
                border-collapse: collapse;
                table-layout: fixed;
            }

            tbody, thead {
                padding: 0;
                width: 100%;
            }

            tr {
                display: table-row;
            }

            th, td {
                padding: 8px 12px;
                text-align: left;
                background-color: inherit;
                word-wrap: break-word;
                overflow-wrap: break-word;
                vertical-align: top;
                width: 1%;  // This makes all columns equal width
            }

            // Equal width for all cells in a row
            tr > * {
                flex: 1;
            }
            tbody {
                background-color: inherit;
                tr {
                    background-color: inherit !important;
                    td {
                        background-color: inherit;
                    }
                }
    
                th {
                    background-color: inherit;  
                    font-weight: 600;
                }
            }

            tr:nth-child(odd) {
                background-color: #f9f9f9;
            }

            // Style for merged cells
            [colspan] {
                text-align: center;
                width: auto;
            }
        }
    }
}
